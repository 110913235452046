import { create } from 'zustand'
import { createTrackedSelector } from 'react-tracked'
import produce from 'immer'
import { subscribeWithSelector } from 'zustand/middleware'
import { getAdvertiseList } from '@/apis/common'
import { AdvertiseListData } from '@/typings/api/welfare-center/advertise'

type IStore = ReturnType<typeof getStore>

function getStore(set, get) {
  return {
    // 广告列表数据
    advertiseData: {
      advertiseList: [] as AdvertiseListData[],
    },
    /** 广告列表 */
    async fetchAdvertiseList() {
      let data = await getAdvertiseList({})
      set(
        produce((draft: IStore) => {
          draft.advertiseData.advertiseList = data.data || []
        })
      )
    },
  }
}

const baseAssetsFuturesStore = create(subscribeWithSelector(getStore))

const useBaseAdvertise = createTrackedSelector(baseAssetsFuturesStore)

export { useBaseAdvertise, baseAssetsFuturesStore }
