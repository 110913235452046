import { usePageContext } from '@/hooks/use-page-context'
import { AdvertiseListImageInfoListData } from '@/typings/api/welfare-center/advertise'
import { useEffect, useState } from 'react'
import { AdsStyleCdEnum } from '@/constants/advert'
import { useBaseAdvertise } from '@/store/advert'
import LazyImage from '@/components/lazy-image'
import { useUserStore } from '@/store/user'
import { advertUserAuth, getPositionCdEnum } from '@/helper/common'
import { advertTolink } from '@/helper/route/advert'
import { oss_svg_image_domain_address } from '@/constants/oss'
import { getAdvertTipsTime, setAdvertTipsTime } from '@/helper/cache/advert'
import { Message } from '@nbit/arco'
import { t } from '@lingui/macro'
import { UserAccountTypeEnum } from '@/constants/user'
import Icon from '../../../components/icon'
import styles from './index.module.css'

function AdvertKycFloat() {
  const { advertiseData, fetchAdvertiseList } = useBaseAdvertise()
  const pageContext = usePageContext()
  const useStore = useUserStore()
  const pathname = pageContext.path
  const [imageInfoList, setImageInfoList] = useState<AdvertiseListImageInfoListData[]>([])
  const [visible, setVisible] = useState(false)
  let time = new Date().getDate()
  let storageTime = '0'

  const close = () => {
    setVisible(false)
    setAdvertTipsTime(`${pathname}-${AdsStyleCdEnum.floatingWindow}-${useStore?.userInfo?.uid}`, time)
  }

  const tolink = () => {
    if (useStore?.userInfo?.memberTag === UserAccountTypeEnum.demoUser) {
      Message.error(t`helper_route_advert_vejuhsgusz`)
      return
    }
    advertTolink(imageInfoList, 0)
    close()
  }

  useEffect(() => {
    storageTime = getAdvertTipsTime(`${pathname}-${AdsStyleCdEnum.floatingWindow}-${useStore?.userInfo?.uid}`)

    advertiseData?.advertiseList?.forEach(item => {
      if (
        getPositionCdEnum(item.positionCd).includes(pathname) &&
        item.styleCd === AdsStyleCdEnum.floatingWindow &&
        storageTime &&
        Number(storageTime) < time
      ) {
        setImageInfoList(item.imageInfoList)
        setAdvertTipsTime(`${pathname}-${AdsStyleCdEnum.floatingWindow}-${useStore?.userInfo?.uid}`, '0')
        setVisible(true)
      }
    })
  }, [advertiseData?.advertiseList, pathname])

  useEffect(() => {
    fetchAdvertiseList()
  }, [])

  return (
    <div>
      {visible && (
        <div className={styles.content}>
          <LazyImage className="icon" src={`${oss_svg_image_domain_address}floatingWindow_icon.svg`} onClick={tolink} />
          <div className="msg" onClick={tolink}>
            {imageInfoList[0]?.msg}
          </div>
          <LazyImage className="banner" src={imageInfoList[0]?.imageUrl} onClick={tolink} />
          <Icon onClick={close} name={'close'} className="close-icon" />
        </div>
      )}
    </div>
  )
}

export default AdvertKycFloat
